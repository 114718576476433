:root {
  --primary-dark: #0f4eb8;
  --primary-lighter: #275fbf;
  --primary-cta: #f47169;
  --primary-cta-hover: #fcd9d7;
  --cta-text-color: #498cdb;
  --primary-white: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /* background-color: #fff !important; */
  width: 100vw;
  height: 100vh;
}

html,
body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin: 0 !important;
  padding: 0 !important;
}

.sidebar {
  height: 100%;
  width: 250px;
  background-color: red;
}

.main-div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: #fff !important; */
}

.logo-banner {
  display: "flex" !important;
  font-size: "30px" !important;
  font-weight: "bold";
  color: "#27ae60";
  justify-content: "center";
  margin-bottom: "30px";
}

.locations-vendor {
  display: grid;
  grid-template-columns: 50% 50% !important;
}

.swal-button--cancel {
  display: flex;
  align-items: center;
}

.swal-button--confirm {
  display: flex;
  align-items: center;
}

/* .locations-number {
  width: 700px !important;
} */

/* .locations-number div {
  margin: "0 10px";
} */

/* .brand-logo {
  padding-left: 2% !important;
} */

/* .MuiPaper-root {
  background-color: unset !important;
} */

.MuiAppBar-root {
  background-color: unset !important;
}

.MuiStepper-root {
  background-color: unset !important;
}

a {
  text-decoration: none !important;
}

.tab {
  text-indent: 50px;
}

.activeTab {
  border-right: 5px solid var(--primary-dark);
}

.listItem:hover {
  background-color: #ededed;
  cursor: pointer;
}

.MuiToolbar-root {
  /* background: var(--primary-lighter) !important; */
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important; */
  padding: 10px;
}

.MuiTextField-root {
  margin: 3% 0 !important;
  width: 280px;
}

.stripeInput {
  width: 243px !important;
  text-align: center !important;
  height: 18px !important;
  font-size: 16px !important;
  /* border-bottom: 1px solid black; */
  /* font: inherit; */
  color: currentColor;
  border: 0;
  height: 1.1875em !important;
  margin: 0;
  display: block;
  padding: 17.5px;
  background: none;
  box-sizing: border-box;
  animation-name: MuiInputBase-keyframes-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  text-rendering: auto;
  color: -internal-light-dark-color(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  /* display: inline-block; */
  text-align: start;
  -webkit-appearance: textfield;
  background-color: -internal-light-dark-color(white, black);
  background-size: 16px 18px;
  background-position: 98% 50%;
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 11px system-ui;
  /* padding: 1px; */
  /* border-width: 1px;
  border-style: inset; */
  /* border: 1px solid #bdbdbd; */
  border-bottom: 1px solid var(--primary-dark);
  /* border-color: initial; */
  /* border-image: initial; */
  /* border-radius: 4px; */
}

/* .strpeInput:focus {
  outline: 0;
  outline-offset: -2px;
} */

.MuiList-padding {
  padding: unset !important;
}

.MuiIconButton-root:hover {
  background-color: unset !important;
}

.blue {
  color: var(--primary-dark);
}

#vid_ui_description {
  display: none;
}

/* #vid_ui_description:hover {
  display: inline !important;
} */

#vid_ui_title {
  font-size: 30px;
  font-weight: 800;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

#suggest-list {
  position: absolute;
  max-height: 60%;
  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  list-style-type: none;
  text-align: left;
  margin: -1px 0px 0px;
  padding: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(187, 187, 187);
  border-image: initial;
  background: #fff;
  z-index: 999999;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

#suggest {
  list-style-type: none;
  width: 17.3rem;
  cursor: default;
  padding: 0.75rem 1.15rem;
}

#suggest:hover {
  background-color: bisque;
  cursor: pointer;
}

#container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#footer {
  text-align: center;
  padding: 15px 0;
}

.MuiList-root:hover {
  background: transparent !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiTableContainer-root {
  box-shadow: unset !important;
}

header {
  box-shadow: unset !important;
}

.MuiCardActions-root {
  padding: 25px !important;
}

.MuiCardHeader-subheader {
  color: #fff !important;
}

a:link {
  text-decoration: none !important;
}

.MuiButton-root:hover {
  text-decoration: none;
  background-color: inherit;
}

.MuiAvatar-root {
  background-color: var(--primary-dark) !important;
}

.no_hover:hover {
  background-color: var(--primary-cta) !important;
  color: var(--primary-white);
}

.MuiButton-outlinedPrimary:hover {
  border: 1px solid var(--primary-cta) !important;
  background-color: var(--primary-cta-hover) !important;
  color: var(--primary-white) !important;
}

.MuiCardHeader-root {
  border-radius: 3px;
}

.MuiCardHeader-action {
  margin: unset !important;
  flex: unset !important;
  align-self: unset !important;
}

.MuiCard-root {
  /* border: 1px solid var(--primary-dark); */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* padding: 5%; */
}

.MuiTableContainer-root {
  /* min-width: 250px !important; */
}

.margin-paragraph {
  color: var(--primary-dark);
  margin: 10% 10% 2% 10%;
}

.actionBtn {
  color: var(--primary-white) !important;
}

.actionBtn:hover {
  background: var(--primary-cta) !important;
}
/* #footer {
  margin-top: calc(5% + 60px);
  padding: 10%;
  bottom: 0;
} */

#sektion {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin: 0 !important; /* change this to whatever height you want it */
}

.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); TODO figure out where this is needed */
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  width: 280px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

#main_div {
  height: 100%;
  width: 100%;
}

.paypal-button:not(.paypal-button-card) {
  min-height: 48px !important;
}

.paypal-button-layout-horizontal {
  min-height: 48px !important;
}

.paypal-button {
  min-height: 48px !important;
}

.rev-cards {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 10px;
}

@media screen and (max-width: 768px) {
  .rev-cards {
    grid-template-columns: 100%;
    justify-items: center;
  }

  .locations-vendor {
    margin-left: 30px;
    background-color: red;
  }
}

.submit-btn {
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  /* &:hover {
    background-color: #2cc36b;
  } */
}
